<script>
import api from '@/services/api';
import PageTitle from '@/components/PageTitle.vue';
import ProgressLinear from '@/components/ui/ProgressLinear/ProgressLinear.vue';
import { BButton, BCol, BPagination, BRow, BTable } from 'bootstrap-vue';
import TableActionBtns from '@/components/TableActionBtns.vue';
import CRUDPageMixin from '@/mixins/CRUDPageMixin';
import { generateIconColorToBoolean, generateIconToBoolean } from '@/util/helper';
import vSelect from 'vue-select';
import { MERCHANT_ID } from '@/constants/names';

export default {
    name: 'AppTournamentProduct',
    components: {
        TableActionBtns,
        ProgressLinear,
        PageTitle,
        vSelect,
        BButton,
        BTable,
        BCol,
        BRow,
        BPagination
    },
    mixins: [CRUDPageMixin],
    data() {
        return {
            items: [],
            merchantOptions: [],
            measurementOptions: [],
            dataForModal: {
                name: {
                    uz: '',
                    ru: '',
                    en: ''
                },
                description: {
                    uz: '',
                    ru: '',
                    en: ''
                },
                images: [],
                images_id: [],
                merchant: null,
                measurement: null,
                qty: null,
                price: null,
                duration: null,
                duration_type: null,
                type: null,
                is_published: 'false'
            },
            pagination: {
                current: 1,
                totalItem: '',
                perPage: 10
            },
            perPageList: [10, 20, 30, 50, 100],
            localLang: localStorage.getItem('lang'),
            requestPending: false,

            fileRecords: [],
            uploadUrl: 'http://dashboard-api.1it.uz/api/uploader/image',
            uploadHeaders: { 'X-Test-Header': 'vue-file-agent' },
            fileRecordsForUpload: [],
            imageUploaded: false,
            loadingImage: false,
            statusOptions: [
                {
                    value: null,
                    label: 'All'
                },
                {
                    value: 'no_publish',
                    label: 'Not published'
                },
                {
                    value: 'publish',
                    label: 'Published'
                }
            ],
            categoryOptions: [],
            filter: {
                is_published: this.$route.query.is_published || null,
                search: this.$route.query.search || '',
                category_id: this.$route.query.category_id || ''
            }
        };
    },

    watch: {
        '$route.query': {
            handler(query) {
                this.pagination.current = parseInt(query.page) || 1;
                this.pagination.perPage = parseInt(query.perPage) || 10;
                this.getProducts();
            },
            deep: true,
            immediate: true
        },

        'pagination.current': {
            handler(page) {
                let newPage = page;
                if (this.pagination.current > this.pagination.totalPage) {
                    newPage = 1;
                }
                this.replaceRouter({
                    ...this.query,
                    page: parseInt(newPage),
                    perPage: parseInt(this.query.perPage) || 10
                });
            }
        },

        'pagination.perPage': {
            handler(perPage) {
                this.replaceRouter({
                    ...this.query,
                    page: 1,
                    perPage: parseInt(perPage)
                });
            }
        },

        'dataForModal.merchant': {
            async handler(value) {
                if (value && value.id) {
                    await this.getMeasurements(value.id);
                }
            },
            deep: true
        }
    },

    computed: {
        fields() {
            return [
                {
                    key: 'id',
                    label: '#'
                },
                {
                    key: 'images',
                    label: this.$t('titles.picture')
                },
                {
                    key: 'name',
                    label: this.$t('titles.title'),
                    formatter: (name) => this.checkLang(name)
                },
                {
                    key: 'description',
                    label: this.$t('titles.description'),
                    formatter: (description) => this.checkLang(description || '').substring(0, 150)
                },
                {
                    key: 'merchant',
                    label: this.$t('titles.merchant'),
                    formatter: (merchant) => merchant?.name || ''
                },
                {
                    key: 'warehouse',
                    label: this.$t('titles.warehouse'),
                    formatter: (warehouse) =>
                        warehouse ? this.checkLang(warehouse?.name || '') : ''
                },
                {
                    key: 'category',
                    label: this.$t('titles.category'),
                    formatter: (category) => (category ? this.checkLang(category?.name || '') : '')
                },
                {
                    key: 'measurement',
                    label: this.$t('navigation.coin_measurement'),
                    formatter: (measurement) =>
                        measurement ? this.checkLang(measurement?.name || '') : ''
                },
                {
                    key: 'price',
                    label: this.$t('titles.price')
                },
                {
                    key: 'qty',
                    label: this.$t('titles.qty')
                },
                {
                    key: 'prize',
                    label: this.$t('titles.prize')
                },
                {
                    key: 'is_published',
                    label: this.$t('titles.published')
                },
                {
                    key: 'actions',
                    label: ''
                }
            ];
        },
        query() {
            return Object.assign({}, this.$route.query);
        },

        hasItems() {
            return this.items && this.items.length > 0;
        },

        showPagination() {
            return this.hasItems && !this.requestPending;
        },

        types() {
            return [
                {
                    key: 'level',
                    label: this.$t('titles.level')
                },
                {
                    key: 'product',
                    label: this.$t('titles.product')
                },
                {
                    key: 'internet',
                    label: this.$t('titles.internet')
                },
                {
                    key: 'voice',
                    label: this.$t('titles.voice')
                },
                {
                    key: 'SMS',
                    label: this.$t('titles.sms')
                },
                {
                    key: 'paynet',
                    label: this.$t('titles.paynet')
                }
            ];
        }
    },

    async created() {
        this.requestPending = true;
        await this.getCategoryList();
        await this.getMerchants();
        this.requestPending = false;
    },

    methods: {
        checkLang(data) {
            const locale = localStorage.getItem('locale') || 'ru';
            if (data && Object.keys(data)) {
                return data[locale].slice(0, 100);
            } else {
                return data || '';
            }
        },

        async getCategoryList() {
            const body = {
                page: 1,
                limit: 30
            };
            await api.productCategory
                .fetchAllCategories(body)
                .then((response) => {
                    this.categoryOptions = response.data.result.map((item) => {
                        return {
                            value: item.id,
                            label: this.checkLang(item.name)
                        };
                    });
                    this.categoryOptions.unshift({
                        value: null,
                        label: 'All'
                    });
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        async getMerchants() {
            const body = {
                method: 'coin.merchant_get_all',
                params: {
                    page: 1,
                    limit: 30
                }
            };
            await api.coin
                .fetchCoinMerchants(body)
                .then((response) => {
                    this.merchantOptions = response.data.result;
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        async getMeasurements(id) {
            const body = {
                method: 'coin.measurement_find_all',
                params: {
                    page: 1,
                    limit: 50,
                    merchant_id: id
                }
            };
            await api.coin
                .fetchCoinMeasurements(body)
                .then((response) => {
                    this.measurementOptions = response.data.result.map((item) => {
                        return {
                            id: item.id,
                            name: this.checkLocales(item.name)
                        };
                    });
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        async getProducts() {
            this.requestPending = true;
            const body = {
                method: 'coin.product_find_all',
                params: {
                    page: this.pagination.current,
                    limit: this.pagination.perPage,
                    is_tournament: true,
                    merchant: MERCHANT_ID,
                    ...this.query
                }
            };
            if (this.query.is_published) {
                body.params.is_published = body.params.is_published === 'publish';
            }
            await api.coin
                .fetchCoinProducts(body)
                .then((response) => {
                    this.items = response.data.result;

                    this.pagination = response.data.pagination;
                    if (this.pagination.current > this.pagination.totalItem) {
                        this.pagination.current = 1;
                    }
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    this.requestPending = false;
                });
        },
        async deleteProduct(uuid) {
            const params = {
                method: 'coin.product_remove',
                params: {
                    uuid: uuid
                }
            };
            await api.coin
                .deleteCoinProduct(params)
                .then(() => {
                    this.getProducts();
                })
                .catch((error) => {
                    this.$toast.error(
                        error.response?.data?.message || error.message || this.$t('error.smth')
                    );
                });
        },
        replaceRouter(query) {
            this.$router.push({ query }).catch(() => {});
        },

        checkLocales(value) {
            if (typeof value === 'object') {
                if (this.localLang === 'uz') {
                    return value.uz;
                } else if (this.localLang === 'ru') {
                    return value.ru;
                } else {
                    return value.en;
                }
            }
            return value;
        },
        publishFiltered(event) {
            if (event && event.value) {
                this.replaceRouter({ ...this.query, is_published: event.value });
            } else {
                this.filter['is_published'] = null;
                delete this.query['is_published'];
                this.replaceRouter({ ...this.query });
            }
        },
        categoryFiltered(event) {
            if (event && event.value) {
                this.replaceRouter({ ...this.query, category_id: parseInt(event.value) });
            } else {
                this.filter['category_id'] = '';
                delete this.query['category_id'];
                this.replaceRouter({ ...this.query });
            }
        },
        searchDebounce(value) {
            if (this.timeoutId !== null) {
                clearTimeout(this.timeoutId);
            }
            this.timeoutId = setTimeout(() => {
                this.searchFiltered(value);
            }, 1000);
        },
        searchFiltered(search) {
            if (search) {
                this.replaceRouter({ ...this.query, search });
            } else {
                this.filter['search'] = '';
                delete this.query['search'];
                this.replaceRouter({ ...this.query });
            }
        },

        stringSlice(string) {
            string.slice(0, 100);
        },

        generateIconToBoolean,
        generateIconColorToBoolean
    }
};
</script>

<template>
    <div>
        <page-title>
            <template #title> {{ $t('navigation.coin_product') }}</template>
            <template v-if="hasAccess('tournament_products', 'create')" #button>
                <b-button
                    v-b-modal.createUpdateModal
                    class="ml-auto"
                    variant="success"
                    @click="$router.push({ name: 'tournament-product-show' })"
                >
                    {{ $t('button.add') }}
                    <feather-icon icon="PlusIcon" />
                </b-button>
            </template>
        </page-title>

        <div class="row d-flex align-items-center justify-content-between mb-2" style="gap: 1rem">
            <div class="col-5">
                <v-select
                    v-model="filter.is_published"
                    :options="statusOptions"
                    :placeholder="$t('choose.status')"
                    :searchable="true"
                    style="background-color: #fff; height: 37px"
                    @input="publishFiltered"
                >
                </v-select>
            </div>
            <div class="col-5">
                <v-select
                    v-model="filter.category_id"
                    :options="categoryOptions"
                    :placeholder="$t('choose.category')"
                    :searchable="true"
                    style="background-color: #fff; height: 37px; max-width: 500px"
                    @input="categoryFiltered"
                >
                </v-select>
            </div>
            <div class="col-3 d-flex align-items-center" style="gap: 1rem">
                {{ $t('search') }}:
                <b-form-group>
                    <b-form-input
                        v-model="filter.search"
                        :placeholder="$t('search')"
                        size="lg"
                        style="height: 37px"
                        @input="searchDebounce"
                    />
                </b-form-group>
            </div>
            <div class="col-4 d-flex align-items-center">
                Per page:
                <v-select
                    v-model="pagination.perPage"
                    :options="perPageList"
                    class="ml-2"
                    style="flex-grow: 1; background-color: #fff; height: 37px; width: 180px"
                />
            </div>
        </div>

        <!--  TABLE  -->
        <div class="card">
            <progress-linear v-if="requestPending" />
            <b-table
                v-else
                :fields="fields"
                :items="items"
                :responsive="true"
                borderless
                class="news__table"
                outlined
                striped
            >
                <template #cell(id)="{ item, index }">
                    {{ index + 1 }}
                </template>

                <template #cell(description)="{ item }">
                    <span class="description" v-html="checkLang(item.description)"></span>
                </template>

                <template #cell(images)="{ item }">
                    <div class="table-img">
                        <img
                            v-if="item.images && item.images.length > 0"
                            :src="item.images[0].path"
                        />
                        <img v-else src="../../../assets/images/icons/no-photo.png" />
                    </div>
                </template>

                <template #cell(type)="{ item }">
                    {{ $t(`titles.${item.type}`) }}
                </template>

                <template #cell(duration_type)="{ item }">
                    {{ $t(`titles.${item.duration_type}`) }}
                </template>

                <template #cell(is_published)="{ item }">
                    <feather-icon
                        :class="['stroke-current', generateIconColorToBoolean(item.is_published)]"
                        :icon="generateIconToBoolean(item.is_published)"
                    />
                </template>

                <template #cell(actions)="{ item, index }">
                    <table-action-btns
                        :delete-access="{ section: 'tournament_products', permission: 'delete' }"
                        :delete-handler="deleteProduct"
                        :index="index"
                        :item="item"
                        :update-access="{ section: 'tournament_products', permission: 'update' }"
                        @openModal="
                            $router.push({
                                name: 'tournament-product-show',
                                params: { id: item.uuid, page: 'tournament-product' }
                            })
                        "
                    />
                </template>
            </b-table>
        </div>

        <!--  PAGINATION  -->
        <b-row>
            <b-col class="offset-3 mb-3" cols="9">
                <b-pagination
                    v-if="showPagination"
                    v-model="pagination.current"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalItem"
                    class="float-right mr-4 mb-0"
                    first-number
                    last-number
                />
            </b-col>
        </b-row>
    </div>
</template>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';

.table-img {
    width: 60px;
    height: 60px;
    background-color: #999999;
    border-radius: 0.25rem;

    & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 0.25rem;
    }
}

::v-deep .vs__dropdown-menu {
    max-height: 250px !important;
}

.card-body {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0;
}

.bordered__image-list {
    border: 2px dashed #999999;

    &_item {
        position: relative;
        width: 100px;
        height: 100px;

        &-close {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            top: -5px;
            right: -5px;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background-color: #999999;
            color: #fff;
            cursor: pointer;
            font-size: 12px;
        }

        & img {
            width: 100px;
            height: 100%;
            object-fit: cover;
        }
    }
}
</style>
